#about-me {
  background: #ced8ec;
  background-size: 100%;
  /* width: 100%;
  height: 100%; */
}

.about-me img {
  padding-top: 25px;
  width: 500px;
  border-radius: 50%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.about-me hr {
  position: relative;
  top: 30px;
  width: 70%;
  margin: 18px auto 24px auto;
  border-color: #000000;
  /* border-color: rgba(150, 150, 150, .1); */
}

.about-me h1 {
  padding-top: 50px;
  text-align: center;
}

.about-me h2 {
  text-align: center;
}

.container{
  display: flex;
  padding-top: 50px;
  padding-left: 150px;
  
}

/* .about-me-profile {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  float: left;
} */

/* .about-content .info-list {
  margin-left: 0;
} */

.about-me-profile p {
  display: -webkit-box;
  margin-block-start: 1em;
  margin-block-end: 1em;
  max-width: 75ch;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

.about-me-profile h3 {
  position: relative;
  font-family: "opensans-bold", sans-serif;
  font-size: 1.9rem;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
}

.about-me-profile .info-list {
  list-style: none;
  padding: 0 0 1.5rem 0;
  margin-bottom: 0.6rem;
  font-size: 20px;
}

.about-me-profile .info-list li strong {
  font-family: "opensans-bold", sans-serif;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 20px;
  line-height: 4rem;
}

.about-me-profile .info-list li span {
  display: block;
  font-family: "opensans-bold", sans-serif;
  color: #000000;
  font-size: 20px;
  line-height: 1.3;
  padding-bottom: 10px;
}

/* .about-me-profile .skills-list li {
  list-style: none;
  padding: 0 0 1.5rem 0;
  margin-bottom: 0.6rem;
  font-size: 20px;
} */

/* .container2{
  display: flex;
  padding-left: 850px;
  padding-bottom: 100px;
} */

.about-me-profile2 h3{
  position: relative;
  font-family: "opensans-bold", sans-serif;
  font-size: 1.9rem;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  padding-left: 31px;
 
}

.skill-bars {
  position: relative;
  list-style: none;
  margin: 4rem 0 3rem;
}

/* Adjust padding of bars */
ul.skill-bars li {
  height: 15px;
  background: #313131;
  margin-bottom: 2.3rem;
  width: 150%;
  position: relative;
}

/* adjust the title of languages */
.skill-bars li strong {
  position: absolute;
  left: 0;
  top: -3rem;
  font-family: "poppins-bold", sans-serif;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 0.9rem;
  line-height: 4.5rem;
  text-align: right;
}

.skill-bars li .progress {
  background-color: #696767;
  height: 15px;
  border-radius: 0;
}

/* Spacing of percentage bars showing progress of lanaguage comprehension */
.skill-bars li .progress span {
  position: absolute;
  top: -25px;
  right: 0;
  font-family: "poppins-regular", sans-serif;
  color: white;
  font-size: 0.7rem;
  line-height: 0.5;
  background: #313131;
  padding: 0.6em 0.6rem;
  border-radius: 8px;
}

/* Arrow for percentage bubble */
.skill-bars li .progress span::after {
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -5px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #313131;
  content: "";
}

.about-me-profile2 .tech-list strong{
  font-family: "opensans-bold", sans-serif;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 20px;
  line-height: 1rem;
}

.tech-list{
  list-style-type: none;
}



.skill-bars li .percent5 {
  width: 5%;
}
.skill-bars li .percent10 {
  width: 10%;
}
.skill-bars li .percent15 {
  width: 15%;
}
.skill-bars li .percent20 {
  width: 20%;
}
.skill-bars li .percent25 {
  width: 25%;
}
.skill-bars li .percent30 {
  width: 30%;
}
.skill-bars li .percent35 {
  width: 35%;
}
.skill-bars li .percent40 {
  width: 40%;
}
.skill-bars li .percent45 {
  width: 45%;
}
.skill-bars li .percent50 {
  width: 50%;
}
.skill-bars li .percent55 {
  width: 55%;
}
.skill-bars li .percent60 {
  width: 60%;
}
.skill-bars li .percent65 {
  width: 65%;
}
.skill-bars li .percent70 {
  width: 70%;
}
.skill-bars li .percent75 {
  width: 75%;
}
.skill-bars li .percent80 {
  width: 80%;
}
.skill-bars li .percent85 {
  width: 85%;
}
.skill-bars li .percent90 {
  width: 90%;
}
.skill-bars li .percent95 {
  width: 95%;
}
.skill-bars li .percent100 {
  width: 100%;
}
