body,
html {
  height: 100%;
}

@font-face {
  font-family: mutka;
  src: url(../images/Lato-Regular.ttf);
}

#homepage {
  margin-bottom: 10px;
  margin-top: auto;
  width: 400px;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
  height: 120%;
  overflow-y: hidden;
}

.homepage-title {
  margin: 0 auto;
  width: 85%;
  padding-bottom: 1px;
}

/* Colby Sawada Title */
header .homepage-title {
  width: 100%;
}
.homepage-title h1 {
  display: block;
  font: 71.4px "mutka", sans-serif;
  color: #fff;
  letter-spacing: 1px;
  margin: 0 auto 18px auto;
  /* text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8); */
  text-align: center;
  padding-top: 50px;
}

.homepage-title h3 span {
  font-size: 20px;
  display: inline-block;
  color: #fff;
  text-align: center;
  /* white-space: nowrap; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.social {
  display: block;
  padding: 10px 24px;
}

/* Center page bar */
.homepage-title hr {
  width: 60%;
  margin: 18px auto 24px auto;
  border-color: #fff;
  /* border-color: rgba(150, 150, 150, .1); */
}

.nav-bar hr {
  width: 100%;
  margin: 65px auto 5px auto;
  border-top: 100px solid white;
}

/* Background Picture */
#home {
  position: relative;
  width: 100%;
  height: 100%;
  background: url('../images/mammoth_1.jpg') no-repeat top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}

/* header social links */
header .social {
  /* margin: 24px 0; */
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}

header .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}

header .social li a {
  color: #fff;
}

header .social li a:hover {
  color: steelblue;
}

.video-container video {
  min-width: 100%;
  /* opacity: 94%; */
  min-height: 100%;
  position: absolute;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
