@import "./../styles/variables.scss";

.container {
  height: calc(100vh - #{$headerHeight});
  background: $bg;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  // h1 {
  //   padding: 1.5rem 2.5rem;
  //   background: linear-gradient(220deg, $primary, rgba($primary, 0.5));
  //   border-radius: $borderRadius calc(#{$borderRadius} * 10);
  // }
}
